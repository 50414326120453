@font-face {
  font-family: 'LL Circular Book Sub';
  src: url('../fonts/lineto-circular-book.eot') format('eot'),
    url('../fonts/lineto-circular-book.woff') format('woff'),
    url('../fonts/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LL Circular Medium Sub';
  src: url('../fonts/lineto-circular-medium.eot') format('eot'),
    url('../fonts/lineto-circular-medium.woff') format('woff'),
    url('../fonts/lineto-circular-medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/lineto-circular-medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
