@import 'src/constants/index.scss';

.faq {
  margin-top: 160px;
  padding: 0 125px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 1200px) {
    padding: 0 75px;
  }

  @media screen and (max-width: $bpSmall) {
    padding: 0 25px;
  }

  section {
    padding: 0;
    margin-top: 81px;
    margin-bottom: 51px;

    @media screen and (max-width: $bpMedium) {
      margin-top: 50px;
    }

    @media screen and (max-width: $bpSmall) {
      margin-bottom: 0;
    }
  }

  .option {
    font-size: 20px;
    font-family: $medium;
    line-height: 32px;
    margin-right: 35px;
    margin-bottom: 17px;
    padding: 0;
    text-align: left;
    text-decoration: underline;
    text-transform: uppercase;
    transition: opacity 0.3s;
    opacity: 0.5;
    width: 17%;

    &:hover,
    &:active {
      opacity: 1;
    }

    @media screen and (max-width: $bpMedium) {
      width: 25%;
    }

    @media screen and (max-width: $bpSmall) {
      margin-bottom: 11px;
      width: 50%;
    }
  }

  .active {
    opacity: 1;
    color: $black;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 69px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $bpSmall) {
      flex-direction: column;
      margin-top: 46px;
    }
  }
}
