@import 'src/constants/index.scss';

.video-player {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 677px;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;

  &:after {
    content: url('../../assets/images/play_icon.png');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.43);
    pointer-events: none;

    @media screen and (min-width: $bpSmall) {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
}

.autoplay,
.playing {
  &:after {
    display: none;
  }
}

.video-container {
  background: transparent;
  margin: 0 auto;
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe,
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 677px;
    object-fit: contain;
  }
}

.thumbnail {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
