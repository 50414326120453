@import 'src/constants/index.scss';

.controls {
  display: flex;
  align-items: center;
  background: $midGrey;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  button,
  a {
    margin-right: 0;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;

    svg {
      margin-left: 0;
    }

    &:hover {
      opacity: 0.5;

      svg {
        transform: none;
      }
    }
  }
}

.close {
  cursor: pointer;
  color: $white;
  font-size: 14px;
  display: inline-block;
  width: 32px;
  height: 32px;

  &::before,
  &::after {
    content: '';
    height: 2px;
    width: 25px;
    background: $white;
    position: absolute;
    top: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(-45deg);
  }

  &::after {
    transform: translateX(-50%) rotate(45deg);
  }
}
