/* Basic */
$black: #000000;
$white: #ffffff;
$offWhite: #f1f1f1;
$darkGrey: #1c1c20;
$midGrey: #555762;
$grey: #dfdfdf;
$salmon: #ff8c74;
/* Hue */
$rose: #f2d9cf;
$roseBrown: #d4afaa;
$blue: #6a6d84;
$darkBlue: #393e5b;
/* Grads */
$salmonGrad: linear-gradient(225deg, #ffc0ac 0%, #ff8c74 100%);
$roseGrad: linear-gradient(225deg, #f2d9cf 0%, #d4afaa 100%);
$darkBlueGrad: linear-gradient(225deg, #6a6d84 0%, #393e5b 100%);
$lightDarkBlueGrad: linear-gradient(
  255deg,
  #393e5b 0%,
  rgba(255, 255, 255, 0.5) 100%
);
