@import 'src/constants/index.scss';

.lightbox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: $white;
  max-width: 100%;
  height: 100vh;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 4.5vh 30px;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;

  @media screen and (min-width: $bpSmall) {
    flex-direction: column;
  }
}

.wrapper {
  text-align: center;
  margin: 0;
  padding: 0 25px;
  max-height: 85%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media screen and (max-height: $bpSmall) and (orientation: landscape) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-rows: 77vh 15vh;
    align-items: center;
    justify-items: center;
    padding: 0 15px;
  }

  @media screen and (min-width: $bpXLarge) {
    height: fit-content; // Safari
  }

  video,
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    display: block;
  }

  // play icon
  span {
    &:after {
      transform: translate(-50%, -50%) scale(0.5);

      @media screen and (min-width: $bpMedium) {
        transform: translate(-50%, -50%) scale(0.7);
      }
    }
  }
}

.description {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 14px;
  width: 100%;

  h3 {
    font-size: 18px;
    line-height: 20px;
  }

  figcaption {
    color: $midGrey;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    width: 100%;

    @media screen and (max-height: $bpSmall) {
      font-size: 14px;
      line-height: 16px;
      margin-top: 5px;
    }

    @media screen and (min-width: $bpSmall) {
      color: $darkGrey;
      line-height: 18px;
      margin-top: 0;
    }
  }
}

.arrow-left,
.arrow-right {
  cursor: pointer;
  display: inline-block;
  height: 11px;
  width: 19px;
  position: absolute;
  top: calc(50% - 8%);
  z-index: 10;

  @media screen and (min-width: $bpSmall) {
    top: calc(50% - 40px);
    height: 22px;
    width: 38px;
  }

  svg {
    fill: $blue;
    margin: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.3s;
    will-change: opacity;
  }

  &:hover {
    svg {
      opacity: 0.5;
    }
  }
}

.arrow-left {
  transform: rotate(90deg);
  left: 10px;

  @media screen and (min-width: $bpMedium) {
    left: 20px;
  }
}

.arrow-right {
  transform: rotate(-90deg);
  right: 10px;

  @media screen and (min-width: $bpMedium) {
    right: 20px;
  }
}
