@import 'src/constants/index.scss';

.title {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  @media screen and (max-width: $bpSmall) {
    pointer-events: all;
  }

  h3 {
    font-family: $medium;
    font-size: 20px;
    line-height: 32px;
    text-align: left;

    @media screen and (max-width: $bpSmall) {
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }
  }

  svg {
    height: 7px;
    width: 10px;
    transition: transform 0.3s ease-in;

    @media screen and (min-width: $bpSmall) {
      display: none;
    }
  }
}

.is-open {
  padding-bottom: 15px;

  .title {
    svg {
      transform: rotate(-180deg);
    }
  }
}
