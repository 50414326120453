@import 'src/constants/index.scss';

.field {
  display: block;
  height: 100%;
}

.controls {
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 28px 25px 25px;
  width: 100%;
  z-index: 1;

  button {
    font-size: 18px;
    border-bottom: 2px solid;
    padding: 0 0 4px 0;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }

    &:disabled {
      opacity: 0.7;
      cursor: default;
    }
  }

  img {
    @media screen and (min-width: $bpXLarge) {
      margin-right: 33px;
    }
  }

  @media screen and (min-width: $bpLarge) {
    background: $offWhite;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    top: 0;
    max-height: 59px;
    height: 100%;
    width: initial;
  }
}
