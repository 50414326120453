/* from: https://github.com/kenwheeler/slick/blob/master/slick/slick.css */

.slick-slider {
  position: relative;
  display: flex;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  margin-top: 75px;

  @media screen and (min-width: $bpSmall) {
    margin-top: 92px;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
  }

  &.engine {
    justify-content: center;

    .slick-list {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1200px) {
        justify-content: initial;
      }
    }
  }

  &.landscape-small {
    .slick-track {
      justify-content: flex-start;

      .slick-slide {
        margin: 0 10px;
      }

      @media screen and (max-width: $bpXLarge) {
        .slick-slide {
          margin: 0 5px;
          width: 350px !important;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      @media screen and (max-width: $bpSmall) {
        .slick-slide {
          width: 204px !important;
          height: 204px !important;
        }
      }
    }
  }

  &.landscape-small {
    margin-top: 40px;

    @media screen and (max-width: $bpXLarge) {
      margin-top: 92px;
    }

    @media screen and (max-width: $bpSmall) {
      margin-top: 60px;

      .slick-slide {
        width: 204px !important;
        height: 204px !important;

        div {
          &:first-of-type {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .slick-arrow {
      top: -74px;
    }
  }

  &.square-small,
  &.featured {
    display: block;
    margin-top: 67px;

    @media screen and (max-width: $bpMedium) {
      margin-top: 57px;
    }

    .slick-track {
      left: 0;
      padding-left: 30px;
      justify-content: center;

      .slick-slide {
        width: 360px;
        overflow: hidden;

        @media screen and (max-width: $bpSmall) {
          width: 207px !important;
        }
      }

      @media screen and (max-width: 600px) {
        justify-content: initial;
        padding-left: 0;
      }

      .slick-slide img {
        width: 100%;
      }
    }

    .slick-arrow {
      top: -89px;
    }
  }

  &.featured {
    .slick-track {
      justify-content: center;
      padding: 0;

      .slick-slide {
        @media screen and (max-width: $bpSmall) {
          width: 300px !important;
        }
      }
    }
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  transition: transform 0.3s;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  transition: transform 0.4s;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: $regular;
  color: $darkGrey;
  font-size: $h3;
  line-height: 45px;
  text-transform: lowercase;
  position: absolute;
  top: -130px;
  will-change: opacity;
  transition: opacity 0.3s;

  @media screen and (max-width: $bpXLarge) {
    font-size: $h3Mobile;
    line-height: 28px;
    top: -121px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.6;
  }

  &.slick-next {
    right: 185px;

    &:hover {
      &:after {
        transform: translateX(8px);
      }
    }

    &:after {
      content: url('../images/arrow.svg');
      position: absolute;
      top: 2px;
      right: -66px;
      height: 20px;
      width: 58px;
      transition: transform 0.5s;
    }

    &.slick-disabled {
      display: none !important;
    }
  }

  &.slick-prev {
    right: 356px;
    z-index: 2;

    &:hover {
      &:after {
        transform: rotate(180deg) translateX(8px);
      }
    }

    &:after {
      content: url('../images/arrow.svg');
      transform: rotate(180deg);
      position: absolute;
      bottom: -6px;
      left: -66px;
      height: 20px;
      width: 58px;
      transition: transform 0.5s;

      @media screen and (max-width: $bpXLarge) {
        bottom: 2px;
      }
    }

    &.slick-disabled {
      display: none !important;
    }

    @media screen and (max-width: $bpXLarge) {
      right: 290px;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        content: '';
        text-align: center;
        opacity: 0.5;
        background: black;
      }
    }

    &.slick-active {
      button {
        &:before {
          opacity: 0.75;
        }
      }
    }
  }
}
