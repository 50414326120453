/* Desktop */
// Universal
$a: 18px;

// Headings
$h1: 120px;
$h2: 60px;
$h3: 35px;
$h4: 20px;

/* Mobile */
$h1Mobile: 60px;
$h2Mobile: 35px;
$h3Mobile: 25px;
$h4Mobile: 16px;
