@import 'src/constants/index';

input {
  color: $darkGrey;
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid $darkBlue;
  font-family: $regular;
  text-align: left;
  font-size: 16px;
  height: 30px;
  width: 100%;
  padding-left: 10px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $darkBlue;
    font-size: 14px;
    text-align: left;
    opacity: 1;
  }

  &.has-error {
    color: $salmon;

    &::placeholder {
      color: $salmon;
    }
  }
}

.error {
  color: $salmon;
}

input[type='checkbox'] {
  width: initial;
}

/*  this removes the default behavior from the autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 2px solid $darkBlue;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 0.5s ease-in-out 0s;
}

input,
textarea {
  outline: none;
  transition: all 0.1s ease-in-out;
}

textarea {
  font-family: $regular;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
  border-radius: 0;
  border-width: 2px;
  box-shadow: none;
  min-height: 92px;
  padding: 10px;
  resize: none;

  &::placeholder {
    color: $midGrey;
    font-family: $regular;
    font-size: 16px;
    line-height: 20px;
  }

  &.has-error {
    color: $salmon;
  }
}

label {
  color: $midGrey;
  font-family: $regular;
  font-size: 16px;
  line-height: 32px;
}

/*  custom react-select styling 
*/
.react-select-container {
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    svg {
      color: $darkGrey;
      width: 15px;
    }
  }

  .react-select__control {
    background: $offWhite;
    border-radius: 0;
    border: none;

    &:hover,
    &--is-focused {
      outline: none;
      box-shadow: none;
    }

    .react-select__value-container {
      cursor: pointer;
      color: $darkGrey;
      font-size: 16px;
      line-height: 20px;

      .react-select__placeholder {
        color: $darkGrey;
      }
    }
  }

  .react-select__control--is-disabled {
    .react-select__value-container {
      .react-select__placeholder {
        opacity: 0.5;
      }
    }
  }

  .react-select__option {
    border-bottom: 1px solid $offWhite;
    font-size: 16px;
    line-height: 20px;

    &:last-of-type {
      border-bottom: none;
    }

    &--is-selected {
      background: $offWhite;
      color: $darkGrey;
    }

    &--is-focused {
      background: $grey;
      color: $darkGrey;
    }
  }

  .react-select__menu {
    background: lighten($offWhite, 3);
    border-radius: 8px;
    margin-top: 3px;
  }
}
