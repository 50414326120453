@import 'src/constants/index.scss';

.privacy-and-terms {
  color: $darkGrey;
  padding-top: 150px;

  .privacy-and-terms-title {
    font-size: 60px;
    font-family: $medium;
    line-height: 60px;
    text-transform: lowercase;
    margin-bottom: 65px;
    padding-left: 114px;
    padding-right: 112px;

    @media screen and (max-width: $bpXLarge) {
      padding-left: 64px;
      padding-right: 62px;
    }

    @media screen and (max-width: $bpMedium) {
      font-size: 35px;
      line-height: 40px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .privacy-and-terms-content {
    padding-left: 114px;
    padding-right: 112px;

    @media screen and (max-width: $bpXLarge) {
      padding-left: 64px;
      padding-right: 62px;
    }

    @media screen and (max-width: $bpMedium) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  h3,
  h2 {
    font-weight: 300;
    text-transform: lowercase;
    margin: 44px 0;
  }

  p {
    font-size: 18px;
    line-height: 30px;
  }

  ul {
    font-size: 18px;
    line-height: 30px;
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    padding-left: 5px;
  }
}
